// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

///////////
url_PostHotels_b2c:'https://aurora.dotcom.tn/api/b2c/B2cStaticHotels',
urlAutoComplete: 'https://aurora.dotcom.tn/api/AutoComplete',
HotelSearchAllSources: 'https://aurora.dotcom.tn/api/HotelSearchAllSources',
url_CashBookingMyGo:'https://aurora.dotcom.tn/api/xml/bookingMyGoSoldeOrCash',
url_CashBookingTnBeds:'https://aurora.dotcom.tn/api/xml/bookingTunisiaBedsSoldeOrCash',
urlImageHotel: "https://aurora.dotcom.tn/api/Images/Hotel/",
KonnectInit:"https://aurora.dotcom.tn/api/xml/KonnectInitBookingPaimentEnLigne",
url_configs_booking:'https://aurora.dotcom.tn/api/b2c/B2cBookConfig',

  

}


