import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: VexRoutes = [
 


  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/apps/home/home.module').then(m => m.HomeModule),

  }, 
  {
    path: 'Tunis',
    loadChildren: () => import('./pages/apps/tunis/tunis.module').then(m => m.TunisModule),

  }, 
  {
    path: 'searching',
    loadChildren: () => import('./pages/apps/searching/searching.module').then(m => m.SearchingModule),
  },





]
  ;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
