import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UniversalAppInterceptor } from './pages/pages/auth/UniversalAppInterceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PopoverModule } from '../@vex/components/popover/popover.module';
import { MatDialogModule } from '@angular/material/dialog';

import { NgxSpinnerModule } from 'ngx-spinner';



// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  /* for development
  return new TranslateHttpLoader(
      http,
      '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
      '.json'
  ); */
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [AppComponent
        ],
  exports:[],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PopoverModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    MatDialogModule,
    NgxDaterangepickerMd.forRoot(),
    // Vex
    VexModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
  }),
  
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: UniversalAppInterceptor, multi: true }, ],
  bootstrap: [AppComponent]
})
export class AppModule { }
